.team-1
{
    display: flex;
    background-color: white;
    padding: 20px;
    text-align: center;
    width: 70%;
    max-height: 500px;
    border-radius: 20px;
    align-self: flex-end;
    animation: 0.5s ease-out 0s 1 slideInFromRight;
}
.team-1 > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.team-1 .image
{
display: flex;
align-items: center;
justify-content: center;
} 

.team-1 img
{
  border-radius: 20px;
}

.team-2
{
  align-self: flex-start;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromRight {
    0% {
      transform: translateX(33%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 100;
    }
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-33%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 100;
    }
  }

  .team-1 h2 {
font-size: 2.0rem;
  }

  @media (max-width: 760px)
  {
    .team-1
    {
      align-self: center;
    }
    .team-1 > div:last-child
    {
      display: none;
    }
    .team-1 > div
    {
      width: 100%;
      align-self: center;
    }
  }