.navbar
{
    z-index: 2;
    background-color: white;
    position: fixed;
    height: 100vh;
    padding: 20px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 300px;

}

.navbar > div
{
    display: flex;
    flex-direction: column;
    align-items: center;    gap: 15px;
    margin-bottom: 10px;
}
.navbar div div
{
    position: relative;
}
.navbar div div div
{
    position: absolute;
    width: 230px;
    top: 0px;
    left: 230px;
    background-color: #66b2d9;
    border-radius: 12px;
}
.navbar div div div a p
{
    font-size: 1.8rem;
    width: 150;
}

.navbar img 
{
    max-width: 100%;
    object-fit: cover;

}

 .navbar div a
{
    text-decoration: none;
} 

.navbar div a:visited
{
    color: initial;
}
.navbar div a:link
{
    color: inherit;
}

.navbar div a p
{
    color: white;
    width: 230px;
    padding: 10px 0;
    font-size: 2.8rem; 
    text-align: center;
    text-shadow: -1px -1px 0 #3d9bc7, 1px -1px 0 #3d9bc7, -1px 1px 0 #1c6080, 1px 1px 0 #3d9bc7;
    background-color: #66b2d9;
    border-radius: 12px;
}

.navbar div a p:hover
{
    background-color: #549EC3;
}

.nav-mobile
{
    display: none;
}


@media (max-width: 760px)
{
    .navbar
    {
        display: none;
    }
    .nav-mobile
    {
        display: block;
        position: fixed;
        width: 100%;
        font-weight: 500;
        font-size: 2.2rem;
        z-index: 3;
    }
    .nav-burger
    {
        width: 100%;
        background-color: #3d9bc7;
        height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        gap: 7vh;
        align-items: center;
        justify-content: center;
    }
    .nav-burger a
    {
        color: white;
        font-weight: 500;
        font-size: 2.2rem;
    }
    .nav-top
    {
        height: 60px;
        display: flex;
        background-color: white;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .nav-top > p
    {
        font-weight: 500;
        font-size: 2.2rem;
    }
    .nav-top > img
    {
        position: absolute;
        left: 20px;
    }
    .nav-under
    {
        position: absolute;
        left: 0;
        top: 70px;
        width: 100%;
        background-color: #3d9bc7;
        height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        gap: 7vh;
        align-items: center;
        justify-content: center;
    }
}