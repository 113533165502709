@font-face 
{
  font-family: "Nunito";
  src: url("../public/fonts/Nunito.ttf");
}

* 
{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html 
{
  font-size: 67.5%;
  font-family: "Nunito";
}

body 
{ 
  background-color: #e0ebf2; 
}

.App
{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main 
{
 
  margin-left: 300px;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  color: #203743;
}

.main ul
{
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header
{
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.footer
{
  background-color: #203743;
  margin-top: auto;
  margin-left: 300px;
  padding: 50px;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-around;
  color: #eee8a9;
}

.footer > div
{
  display: flex;
  gap: 2vw;
}

.footer > div > div
{
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.footer > div > a 
{
  color: inherit;
}
.footer > div > a:hover
{
  color: #c6be5c;
}

h2
{
  margin: 30px 0;
  font-size: 4.0rem;
}

h3
{
  font-size: 2.8rem;
}
a{
font-size: 2.0rem;
}

li
{
  font-size: 2.0rem;
}

p
{
  font-size: 2.0rem;
}

.gallery
{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.gallery img
{
  width: 20vw;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.spenden
{
  align-items: flex-start;
}

.paypal
{
  display: flex;
  flex-direction: column;
}
.paypal > div
{
  display: flex;
  gap: 20px;
  align-items: center;
}

hr 
{
  width: 100%;
}

.spenden h3
{
  margin-bottom: 40px;
}
.pate
{
  display: flex;
  flex-direction: column;
  gap: 20px;
} 
.pate a 
{
  margin-top: 50px;
}

.sponsoring
{
  display: flex;
  flex-direction: column;
}

.sponsoring > div
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;

}
.sponsoring > div > div
{
  width: 75%;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 1px solid black;

}

.sponsoring > div > a
{
  margin: auto;
  justify-self: center;
}

.sponsoring > div > iframe 
{
  margin: auto;
  justify-self: center;
}

.sponsoring > div > a > img
{
  width: 200px;
  margin: auto;
  justify-self: center;
  object-fit: contain;
}
 .vermittlung
{
  width: 100%;
}

.vermittlung a
{
  width: 100%;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vermittlung > a > div
{
  display: flex;
  border-radius: 5px;
  width: 60%;
  margin: 20px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
}

.vermittlung > a:hover > div
{
  width: 61%;
  font-weight: bold;
}

.vermittlung > a > div > img
{
  justify-self: flex-start;
  border-radius: 5px 0 0 5px;
}
.vermittlung > a > div > p
{
  justify-self: flex-end;
  margin-right: 40px;
}

.news
{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.berichte
{
  width: 70%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.berichte > div
{
  display: flex;
  align-items: center;
  gap: 10px;
}
.berichte > div > p
{
  color: #203743;
}

.berichte div > a
{
  color: inherit;
  width: 80%;
  text-decoration: none;
}

.berichte div > a > p
{
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid rgb(149, 149, 149);
  border-radius: 20px;
}

.berichte div > a:hover > p
{
  background-color:#9fcfe7;
  color: white;
}

.spendenaufkommen
{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.spendenaufkommen > div
{
  display: flex;
  flex-direction: column;
  border: solid 1px black;
  border-bottom: 0;
}

.spendenaufkommen > div > div
{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: solid 1px black;
}

.partner
{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid black 1px;
}

.partner > div
{
  width: 100%;
  background-color: white;
  padding: 50px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.partner div a img
{
  max-width: 800px;
  max-height: 100px;
}

.blue
{
color: #5c94b4;
font-size: 1.8rem;
}

.partner2
{
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.links
{
font-size: 3.6rem;
font-weight: bold;
}
.mitglied > p
{
 width: 80%;
}

.mitglied > div{
  text-align: center;
}
.mitgliedschaft
{  
  width: 60%;
  display: flex;
  flex-direction: column;
  border: solid black 1px;
}
.mitgliedschaft > div
{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: solid black 1px;
}
.mitgliedschaft > div:last-child
{
  border-bottom: none;
}

.mitgliedschaft > div > p 
{
  width: 50%;
  text-align: left;
}

.mitgliedschaft > div > p:nth-child(2) {
  text-align: center;
}
.mitgliedschaft > div > hr
{
  width: 1px;
  height: 45px;
}

.impressum
{
  align-items: flex-start;
}
.impressum h2
{
  align-self: center;
}
.impressum div h3
{
  margin-bottom: 10px;
}

.wunsch
{
  text-align: center;
}
.wunsch > div
{
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 70%;
}

.wunsch > div > a > p{
  margin: 20px;
}

@media (max-width: 760px)
{
  html
  {
    font-size: 60%;
  }
  h2
  {
    margin: 10px 0 0 0;
  }
    .main
    {
        margin-left: 0;
        text-align: center;
        padding: 0;
        padding-top: 70px;
        gap: 10px;
        margin-bottom: 40px;
    }
    .main ul 
    {
      width: 85%;
    }
    .main > *:not(img)
    {
      padding: 20px;
    }
    .footer
    {
      text-align: center;
      margin-left: 0;
      flex-direction: column;
    }
    .footer > div
    {
      flex-direction: column;
    }
    .gallery
    {
      grid-template-columns: repeat(1, 1fr);
    }
    .gallery img
    {
      width: 70vw;
    }
    .paypal div table 
    {
      display: none;
    }
    .mitgliedschaft
    {
      width: 90%;
    }
    .sponsoring > div 
    {
      flex-direction: column;
    }

    .spenden 
    {
      align-items: center;
    }

    .sponsoring > div > div
    {
      width: 100%;
      border-left: none;
      border-top: solid black 1px;
    }
    .sponsoring > div > a
    {
      margin: 50px;
    }
    .wunsch img
    {
      object-fit: cover;
      width: 100%;
    }
    .vermittlung > a > div
    {
      flex-direction: column;
      width: 50%;
      font-weight: 500;
    }
    .vermittlung > a > div > p
    {
      margin-right: 0;
      padding: 20px;
    }
    .vermittlung > a > div > img
    {
      object-fit: contain;
      width: 100%;
    }
    .berichte
    {
      width: 90%;
    }
    .berichte > div
    {
      flex-direction: column;
    }
    .partner div a 
    {
      width: 100%;
    }
    .partner div a img 
    {
      object-fit: contain;
      width: 100%;
    }
    .aegina
    {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .aegina > img
    {
      width: 100%;
      object-fit: contain;
    }
}